import { createContext, Component } from 'react'
import { INITIAL_DRAFT, INIT_SELF_SEND } from 'services/constants'

const GlobalContext = createContext({})

class GlobalProvider extends Component {
  state = {
    currentUser: {},
    team: {},
    draft: INITIAL_DRAFT,
    selfSend: INIT_SELF_SEND,
    isImpersonating: false,
    version: 0,
    showRefresh: false,
    setGlobalState: stateObject => {
      this.setState(stateObject)

      // only for regular sends - save state to localStorage
      const isSelfSend = this.state.selfSend.isSelfSend

      if (typeof window !== 'undefined' && !isSelfSend) {
        localStorage.setItem('draft', JSON.stringify(stateObject.draft))
      }
    },
  }

  render() {
    return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>
  }
}

export { GlobalContext, GlobalProvider }
