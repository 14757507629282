import App from 'next/app'
import Head from 'next/head'
import { GlobalProvider } from 'components/global'
import Router from 'next/router'
import NProgress from 'nprogress'
import styles from '../styles/index.scss'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

NProgress.configure({
  trickleSpeed: 150, // default is 200
  showSpinner: false,
  minimum: 0.15,
})

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c92680c43be9472fbcccb48633cc9fa3@sentry.io/1862757',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const DefaultLayout = ({ children }) => children

class CustomApp extends App {
  state = {
    outdatedBrowser: false,
  }

  async componentDidMount() {
    Router.events.on('routeChangeStart', toPath => {
      NProgress.start()
    })

    Router.events.on('routeChangeComplete', url => {
      // hack to make doc title work properly (push back on call stack)
      setTimeout(() => {
        NProgress.done()

        window.analytics.page({
          title: document.title,
          url: window.location.href,
          path: url,
        })
      }, 1)
    })

    // only exists in IE
    if (window.document.documentMode) {
      this.setState({ outdatedBrowser: true })
    }
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart')
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key])
        })

        Sentry.captureException(error)
      })

      super.componentDidCatch(error, errorInfo)
    }
  }

  render() {
    const { Component, pageProps } = this.props
    const Layout = Component.Layout || DefaultLayout

    return (
      <>
        <Head>
          <title>Handwrite | Handwritten cards made easy</title>
          <meta
            name="description"
            content="We make it easy and affordable to send letters from any device in seconds."
          />

          <meta
            name="viewport"
            content="width=device-width,minimum-scale=1,initial-scale=1, user-scalable=0"
          />

          <link rel="manifest" href="/manifest.json" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="application-name" content="Handwrite" />
          <meta name="apple-mobile-web-app-title" content="Handwrite" />
          <meta name="theme-color" content="#262730" />
          <meta name="msapplication-navbutton-color" content="#262730" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
          <meta name="msapplication-starturl" content="/send" />

          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="https://res.cloudinary.com/handwrite/image/upload/c_thumb,w_192,g_face/v1571801416/assets/favicons/192x192.png"
          />
          <link
            rel="apple-touch-icon"
            type="image/png"
            sizes="192x192"
            href="https://res.cloudinary.com/handwrite/image/upload/c_thumb,w_192,g_face/v1571801416/assets/favicons/192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="512x512"
            href="https://res.cloudinary.com/handwrite/image/upload/c_thumb,w_512,g_face/v1571801424/assets/favicons/512x512.png"
          />
          <link
            rel="apple-touch-icon"
            type="image/png"
            sizes="512x512"
            href="https://res.cloudinary.com/handwrite/image/upload/c_thumb,w_512,g_face/v1571801424/assets/favicons/512x512.png"
          />

          <link
            href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
          />

          <link
            href="https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap"
            rel="stylesheet"
          />

          <link href="https://fonts.googleapis.com/css?family=Dancing+Script&display=swap" rel="stylesheet" />

          <script src="https://js.stripe.com/v3" />

          <script
            dangerouslySetInnerHTML={{
              __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/a6bl0tcv';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
            }}></script>
        </Head>
        <GlobalProvider>
          {this.state.outdatedBrowser && (
            <div className="outdated-warning">
              Heads up! You are using an outdated browser that may not function properly.
            </div>
          )}
          <Layout user={pageProps.currentUser || {}}>
            <Component {...pageProps} />
          </Layout>
        </GlobalProvider>
      </>
    )
  }
}

export default Sentry.withProfiler(CustomApp)
